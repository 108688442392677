import React from 'react'
import Helmet from 'react-helmet'
import {
    VerticalTimeline,
    VerticalTimelineElement,
} from 'react-vertical-timeline-component'

import get from 'lodash/get'
import { graphql } from 'gatsby'

import { rhythm } from '../utils/typography'

import Layout from '../components/Layout'
import exposData from '../data/expos'

import 'react-vertical-timeline-component/style.min.css'
import '../styles/journey.page.css'

class Expos extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const siteDescription = get(this,'props.data.site.siteMetadata.description');

    return (
      <Layout style={{ maxWidth: rhythm(45) }}>
        <Helmet
          htmlAttributes={{ lang: 'fr' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`${siteTitle} | Expositions`}
        />

        <h2>Expositions</h2>

        <div>
          <VerticalTimeline animate={false}>
            {exposData.map(e => (
              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                date={e.date}
                iconStyle={{ background: '#fff', color: '#34495e' }}
                icon={e.icon}
                key={e.headline}
              >
                <h3 className="vertical-timeline-element-title">
                  {e.headline}
                </h3>
                <br />
                  <img
                      src={e.image}
                      alt={e.headline}
                  />
                {/*<LightboxImage*/}
                {/*  src={e.image}*/}
                {/*  title={e.headline}*/}
                {/*  caption={e.description}*/}
                {/*/>*/}
                {e.description}<br/>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        </div>
      </Layout>
    )
  }
}

export default Expos

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
