import React from 'react'
import { FaAward, FaPaperPlane } from 'react-icons/fa'

import ExpoValencienne from './../assets/images/valenciennes/affiche_v2.jpg'
import ExpoDouai from './../assets/images/douai/affiche.jpg'
import ExpoCharleville from './../assets/images/Charleville/affiche.jpg'

const data = [
  {
    date: 'Octobre 2021',
    headline: 'Exposition de Charleville-Mézières',
    description: `Troisième exposition du Collectif d'Artistes du Hainaut Belge à Charleville-Mézières, du 15 au 20 Octobre 2021.`,
    image: ExpoCharleville,
    icon: <FaAward />,
  },
  {
    date: 'Septembre 2019',
    headline: 'Exposition de Douai',
    description: `Seconde exposition du Collectif d'Artistes du Hainaut Belge à Douai, du 6 au 8 Septembre 2019.`,
    image: ExpoDouai,
    icon: <FaAward />,
  },
  {
    date: 'Mai 2019',
    headline: 'Exposition de Valenciennes',
    description: `Première exposition du Collectif d'Artistes du Hainaut Belge à Valenciennes, du 18 au 23 Mai 2019. Invité d'honneur : André Gobert.`,
    image: ExpoValencienne,
    icon: <FaAward />,
  }
];

export default data
